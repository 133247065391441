/* General Page Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.article-text {
  font-size: 16px;;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

/* Header Styles */
header {
  background-color: #121212;
  color: #e0e0e0;
  width: 100%;
  padding: 38px 0;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
}

.logo-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: -45px;
  left: -15px;
  z-index: 4;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5px solid yellow;
  background-color: #fff;
  padding: 20%;
  box-shadow: 0 0 0 5px orange, 0 0 0 10px red;
  position: relative;
  top: 20px;
}

.headerimg {
  max-height: 100px;
  display: block;
}

.scrolled {
  transform: translateY(-100%);
}

.small-logo {
  position: fixed;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  z-index: 1000;
}

.small-logo .circle {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 3px orange, 0 0 0 6px red;
}

.small-logo img {
  max-height: 40px;
}

/* Navigation Styles */
.nav-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;
  transition: opacity 0.3s ease-in-out;
}

.nav-container a {
  color: #4caf50;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  margin: 15px;
}

.nav-container a:hover {
  color: #ffeb3b;
  transition: 0.3s;
}

/* Color Lines */
.color-lines {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: bottom 0.3s ease;
  z-index: 3;
}

.color-lines .line-yellow {
  height: 5px;
  background-color: yellow;
}

.color-lines .line-orange {
  height: 5px;
  background-color: orange;
}

.color-lines .line-red {
  height: 5px;
  background-color: red;
}

.color-lines.expand-lines {
  bottom: -250px; /* Move down to reveal the menu */
}

/* Main Content Styles */
main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  min-height: 520px;
}

.content-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.article-card {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 23px;
  margin-top: 17px;
  min-width: 97%;
}

.article {
  width: 70%;
  box-sizing: border-box;
}

.article h2 {
  color: #4caf50;
  margin-bottom: 10px;
}

.article a {
  color: #ffc107;
  text-decoration: underline;
}

/* Footer Styles */
.footer {
  background-color: #333; /* Matching the header for consistency */
  color: #e0e0e0;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden; /* Ensure content does not overflow */
  z-index: 1000;
}

.footer-color-lines {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 9px; /* Adjusted height to match the header's color lines */
}

.footer-color-lines .line-yellow {
  height: 3px;
  background-color: yellow;
}

.footer-color-lines .line-orange {
  height: 3px;
  background-color: orange;
}

.footer-color-lines .line-red {
  height: 3px;
  background-color: red;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.footer-quick-links {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.footer-quick-links h3 {
  margin-bottom: 10px;
}

.footer-quick-links ul {
  list-style: none;
  padding: 0;
}

.footer-quick-links li {
  margin-bottom: 5px;
}

.footer-quick-links a {
  color: #4caf50;
  text-decoration: none;
  font-size: 18px;
}

.footer-logo-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 4vw;
  flex: 1;
}

.footer-logo-background {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5px solid yellow;
  background-color: #fff;
  padding: 60px;
  box-shadow: 0 0 0 5px orange, 0 0 0 10px red;
  position: relative;
  top: 20px;
}

.footer-logo {
  height: 110px;
  margin: -40px;
}

.footer-bottom-left {
  flex: 1;
  text-align: left;
  padding-left: 20px;
  align-self: flex-end;
}

.footer-bottom-left p {
  margin: 0;
  font-size: 12px;
}

.footer-built-by {
  text-align: center;
  padding: 10px;
  background-color: #333;
  color: #e0e0e0;
  margin-bottom: -25px;
}

.footer-built-by p {
  margin: 0;
  font-size: 14px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  position: fixed;
  top: 140px;
  left: 25px;
  z-index: 1;
  width: 250px;
}


.filter-box {
  background-color: #333; /* Dark background */
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-input, .filter-select {
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #444;
  color: #e0e0e0;
  width: 90%;
}

.filter-select {
  cursor: pointer;
}

.article-list {
  margin-left: 280px; /* Adjust this value to leave space for the filter section */
  flex: 1;
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  min-height: 520px;
  justify-content: center;
  align-items: center;
}

.article-list .article-card {
  flex: 0 1 45%;
}

.filter-button {
  margin-top: 10px;
  background-color: #ff9800; /* Orange color */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Slower transition */
}

.filter-button:hover {
  background-color: #e67e22; /* Darker orange on hover */
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}

/* Arrow Styles */
.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
  z-index: 1000;
  background: grey;
  border-radius: 50%;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  color: white;
}

.slick-prev {
  left: -35px;
}

.slick-next {
  right: -35px;
}

.ad-space {
  color: #e67e22;
  text-align: center;
  box-sizing: border-box;
  object-fit: fill;
}

.all-articles-ad {
 max-height: 200px;
 max-width: 240px;
 border-radius: 5px;
}

.rect-ad-space {
  color: #e67e22;
  padding: 100px;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;
  margin: -100px; /* Adds space between ads and articles */
}

.rectangular-ad {
  max-width: 100%;
  max-height: 300px;
  object-fit: fill; /* Ensure the image fits within the container without stretching */
  display: block;
  margin: 0 auto; /* Center the ad */
  border-radius: 5px;
}

.footer-header {
  font-size: 32px;
  margin-bottom: -10px;
  margin-left: -5px;
}

.dual-ads {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-bottom: 25px;
}

.dual-ad {
  max-height: 200px;
  border-radius: 15px;
}

.home-rectangular-ad {
  max-width: 60%;
  max-height: 300px;
  object-fit: fill;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.no-articles-message {
  color: #ff9800; /* Matching the orange used in buttons */
  font-size: 20px;
  text-align: center;
  padding: 20px;
  background-color: #2c2c2c; /* Same background as article cards */
  border-radius: 8px;
  border: 1px solid #444;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
  min-width: 50%;
  margin-bottom: 25%;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-quick-links {
    text-align: center;
    padding-left: 0;
  }

  .footer-bottom-left {
    text-align: center;
    padding-left: 0;
  }
}

/* Mobile Styles */
.hamburger-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1;
  width: 35px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.hamburger-icon span {
  width: 100%;
  height: 3px;
  background-color: #4caf50;
  margin: 4px 0;
  transition: 0.3s ease;
  pointer-events: none; /* Make sure the spans don’t intercept the click event */
}

.hamburger-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(7px, 7px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0; /* Hide the middle bar */
}

.hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

/* Show the hamburger icon and hide nav links on small screens */
/* Color Lines */
.color-lines {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: bottom 0.3s ease; /* Smooth transition for bottom position */
}

.color-lines .line-yellow {
  height: 5px;
  background-color: yellow;
}

.color-lines .line-orange {
  height: 5px;
  background-color: orange;
}

.color-lines .line-red {
  height: 5px;
  background-color: red;
}

/* Expand color lines only when the menu is open */
.color-lines.expand-lines {
  bottom: -230px; /* Move down to reveal the menu */
}

.img-cont {
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .filter-section {
    display: none;
  }

  .article-list{
    margin: 5px;
  }
  .nav-links {
    display: flex;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #121212;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 2;
    margin-top: 34px;
    transition: transform 0.4s ease, opacity 0.4s ease;
    opacity: 0;
    transform: translateY(-20px);
  }

  .dual-ads {
    justify-content: center;
  }

  .nav-links.open {
    opacity: 1;
    transform: translateY(0);
  }

  .nav-links.close {
    opacity: 0;
    transform: translateY(-20px);
  }

  .hamburger-icon {
    display: flex; /* Show the hamburger icon on mobile */
  }

  /* Smooth animation for dropdown menu */
  .nav-links.show-menu {
    animation: slideDown 0.3s ease-in-out forwards;
  }

  .dual-ad {
    padding: 20px;
    border-radius: 35px;
    height: 25vw;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Slide-up animation for closing */
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}


/* Color Lines for dropdown menu */
.nav-links.show-menu .line-yellow,
.nav-links.show-menu .line-orange,
.nav-links.show-menu .line-red {
  height: 5px;
}