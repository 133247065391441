/* Article Summary Section */
.article-summary-section {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  position: relative;
}

hr {
  width: 100%;
  background-color: #3e9941;
  border-color: #3e9941;
  height: 3px;
  border-radius: 10px;
}

/* Recent Articles Header */
.recent-articles-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.homepage-section-header {
  text-align: center;
  color: #4caf50;
  margin: 0;
  flex-grow: 1;
  font-size: 32px;
}

/* Articles Container */
.articles-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Article Summary Styles */
.article-summary {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  max-width: 250px;
  text-align: left;
}

.article-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
  max-height: 100%;
}

.article-summary h3 {
  color: #4caf50;
  margin-bottom: 10px;
}

.article-summary p {
  margin-bottom: 10px;
}

.article-summary a {
  color: #ffc107;
  text-decoration: underline;
}

/* Custom Arrow Styles */
.custom-arrow {
  background: transparent;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  position: absolute;
}

.left-arrow {
  left: 20px;
  font-size: 40px;
  color: #4caf50;
}

.right-arrow {
  right: 20px;
  font-size: 40px;
  color: #4caf50;
}

/* Special Events Section */
.special-events-section {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* Calendar Section */
.calendar-section {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .articles-container {
    flex-direction: column;
    align-items: center;
  }

  .article-summary {
    width: 90%;
    margin-bottom: 20px;
  }

  .recent-articles-header .left-arrow {
    left: -30px;
  }

  .recent-articles-header .right-arrow {
    right: -30px;
  }

  .special-events-section,
  .calendar-section {
    width: 90%;
    margin-bottom: 20px;
  }

  .custom-arrow {
    display: none;
  }
}


.special-events-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 400px;
}

.homepage-section-header {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.events-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.event-spotlight {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  width: 75%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.event-spotlight h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.event-spotlight p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.event-link {
  color: #ffc107;
  text-decoration: underline;
}

/* Custom Scrollbar Styling */
.events-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.events-container::-webkit-scrollbar-track {
  background: #333; /* Dark background for scrollbar track */
  border-radius: 10px;
}

.events-container::-webkit-scrollbar-thumb {
  background-color: #555; /* Darker color for scrollbar thumb */
  border-radius: 10px;
  border: 2px solid #333; /* Add padding around thumb */
}

.events-container::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* Lighter color on hover */
}