.calendar {
  max-width: 100%;
  margin: 20px auto;
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #4caf50;
}

.calendar-arrow {
  color: #4caf50; /* Weed green */
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-size: 32px; /* Adjust the size as needed */
  background: none;
  border: none;
}

.calendar-arrow:hover {
  color: #388e3c; /* Darker green on hover */
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.calendar-day-name {
  text-align: center;
  font-weight: bold;
  color: #4caf50;
}

.calendar-day {
  position: relative;
  padding: 5px;
  background-color: #333;
  border-radius: 8px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.day-number {
  font-size: 12px;
  color: #ffc107;
  margin-bottom: 5px;
}

.event-title {
  text-align: left;
  font-size: 14px;
  color: #ffeb3b;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none !important;
}

.day-event-title {
  text-align: left;
  font-size: 14px;
  color: #ffeb3b;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none !important;
}

.event-title:hover {
  color: #e0e0e0;
}

.day-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
}

.day-modal-content {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: left;
}

.modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: left;
}

.modal-content a {
  color: #ffc107;
  text-decoration: underline;
}

.close-button {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}

.event-bar {
  display: none;
  color: #ffc107;
  width: 95%;
  height: 3px;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .event-title{
    display: none;
  }

  .event-bar {
    display: flex;
  }

  .day-modal {
    display: flex;
  }

  .modal-content{
    width: 80%;
  }

  .calendar-grid{
    gap: 4px;
  }
}