/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Darker overlay to match the site's theme */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup Content */
  .popup-content {
    background-color: #2c2c2c; /* Dark background to match the articles */
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: #e0e0e0; /* Light text color */
  }
  
  .popup-content h2 {
    color: #4caf50; /* Weed green for headings */
    margin-bottom: 20px;
    text-align: center;
    font-size: 40px;
    margin-top: 0;
  }
  
  .popup-content label {
    font-weight: bold;
    color: #ffc107; /* Gold color for labels */
  }
  
  .popup-content input {
    width: 96.1%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: none;
    background-color: #444; /* Dark input background */
    color: #e0e0e0; /* Light input text color */
  }
  
  .popup-content .button {
    background-color: #4caf50; /* Weed green for the submit button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .popup-content .button:hover {
    background-color: #388e3c; /* Darker green on hover */
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #e0e0e0;
    transition: color 0.3s ease;
  }
  
  .close-popup:hover {
    color: #ffeb3b; /* Yellow on hover */
  }
  
  @media (max-width: 768px) {
    .popup-content{
      width: 75%;
    }

    .popup-content h2 {
      font-size: 32px;
    }
  }