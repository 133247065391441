/* src/css/ageVerificationModal.css */
.age-verification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it overlays other content */
  }
  
  .age-verification-modal {
    padding: 2rem;
    text-align: center;
    border-radius: 8px;
  }
  
  .age-verification-modal h2 {
    margin-top: 0;
  }
  
  .age-verification-modal button {
    margin-top: 1rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .veri-logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        margin-bottom: 35px;
  }

  .logo-header-img {
    max-height: 150px;
  }

  .veri-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 5px solid yellow;
        background-color: #fff;
        padding: 8%;
        box-shadow: 0 0 0 5px orange, 0 0 0 10px red;
        position: relative;
        top: 20px;
  }

  .veri-button {
    margin-top: 10px;
    background-color: #ff9800;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}
  