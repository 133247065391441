/* Contact page main container */
.contact-main {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
  }
  
  .side-ad-space {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  /* Ad container around the contact form */
  .ad-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 1200px;
  }
  
  /* Contact content area with top and bottom ads */
  .contact-content {
    flex: 1;
    text-align: center;
  }
  
  .cont-rect-ad-space {
    margin: 0;
    margin-bottom: 10px;
    padding: 0 30px 0px 30px;
    max-height: 200px;
    margin-top: 10px;
  }
  
  .cont-rectangular-ad {
    height: auto;
    border-radius: 8px;
    max-height: 150px;
  }
  
  /* Styling for square ads on the left and right sides */
  .side-square-ad {
    width: 95%;
    margin-top: 10px;
    margin-bottom: 25px;
    height: auto;
    border-radius: 8px;
  }
  
  /* Original styling for the contact form */
  .contact-page {
    background-color: #2c2c2c;
    color: #ffc107;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: left;
    width: 100%;
  }
  
  h1 {
    font-size: 32px;
    margin: 0;
    margin-bottom: 5px;
  }
  
  .contact-page h2 {
    text-align: center;
    color: #4caf50;
    margin-bottom: 20px;
  }
  
  .contact-page label {
    color: #4caf50;
    font-weight: bold;
    margin-top: 10px;
    display: block;
  }
  
  .contact-page input,
  .contact-page textarea {
    width: 95%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ffc107;
    background-color: #333;
    color: #ffffff;
    font-size: 18px;
  }
  
  .contact-page button {
    width: 95%;
    padding: 12px;
    background-color: #ffc107;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .contact-page button:hover {
    background-color: #ffc107;
  }
  
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .success-message {
    color: #4caf50;
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
    max-width: 600px;
  }
  
  .contact-page input:-webkit-autofill,
  .contact-page textarea:-webkit-autofill {
    background-color: #333 !important;
    border: 1px solid #ffc107 !important;
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  
  .contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .side-ad-space {
        display: none;
    }
  }